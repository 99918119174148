.position {
  &-absolute {
    position: absolute;
  }

  &-relative {
    position: relative;
  }

  &-fixed {
    position: fixed;
  }

  &-sticky {
    position: sticky;
  }
}

.right {
  &-0 {
    right: 0;
  }
}

.top {
  &-0 {
    top: 0;
  }
  &-50 {
    top: 50px;
  }
}

.left {
  &-0 {
    left: 0;
  }
}
