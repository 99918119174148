.icon {
  &-accent {
    fill: $primary-color;

    & > * {
      fill: $primary-color;
      stroke: $primary-color;
    }
  }

  &-muted {
    fill: $muted-color;

    & > * {
      fill: $muted-color;
      stroke: $muted-color;
    }
  }

  &-dark {
    fill: $black-primary-color;

    & > * {
      fill: $black-primary-color;
      stroke: $black-primary-color;
    }
  }
}
