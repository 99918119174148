// text or accent
$primary-color: #4652b7;
// use for highlighting background
$primary-color-light: rgba(70, 81, 183, 0.1);
$black-primary-color: #13141e;
$darker-primary-color: #3b448f;
$muted-color: #666666;
$light-color: #828491;
$white-color: #fff;
$grey-color: #333333;
$text-grey: #5f5f75;
$light-grey: #828282;
$faded-color: #9799a9;
$black-text-faded-color: #9799a9;
$alert-red: #f84242;
$dark-grey: #babaca;
$medium-grey: #666b72;

// background color
$background-primary-light: #fcfcff;
$background-card-container: #f7f7fb;
$background-card-title: #f7f7f7;
$background-accent-container: rgba(70, 82, 183, 0.11);
$background-accent-sidebar: #f7f7ff;
$background-dark-purple: rgba(12, 21, 92, 1);
$background-card-light: rgba(247, 247, 255, 0.7);
$background-performance-review-color: #f3f3fe;

//title
$background-title-light: rgba(215, 215, 233, 0.09);

// border color
$dropdown-border-color: #9799a9;

$border-muted: #d7d7e9;
$border-highlight: rgba(70, 82, 183, 0.37);
$border-accent-container: rgba(70, 82, 183, 0.09);
$border-muted-wrapper: #e5e5e5;
$border-light: rgba(215, 215, 233, 0.24);

// alert color
$alert-background-info: #f0f0fe;
// shadow color
$dropdown-shadow-color: rgba(0, 0, 0, 0.15);
// tag purple color
$tag-background-purple: rgba(215, 215, 233, 0.2);
$tag-text-purple: #4652b7;
$tag-border-purple: rgba(50, 13, 195, 0.23);
// card
$card-box-shadow: rgba(0, 0, 0, 0.08);
$card-background: #fff;
$card-light-ash: #eceeff;

//survey card
$survey-card-background-live: #f2fef5;
$survey-card-background-draft: #fefaee;
$survey-card-background-scheduled: #f5f5ff;
$survey-card-background-completed: #f2f2f3c2;

$survey-status-background-live: #5cb475;
$survey-status-background-draft: #eac237;
$survey-status-background-scheduled: #36b1f6;
$survey-status-background-completed: #ababc9;

//border
$border-grey: #d7d7e9c2;

$status-background: #d7d7e9;
$status-default: #4652b7;

$status-track: #59bd9a;
$status-risk: #bf7171;
$status-not-started: #9799a9;
$status-discarded: rgba(70, 82, 183, 0.2);
$status-postponed: rgb(102, 102, 102);
$status-behind: #6d5a28;
$status-achieved: #3a962b;
$status-not-achieved: #c61d1d;

// OKR specific
$status-track-background-okr: #82d1b5;
$status-risk-background-okr: #d19494;
$status-not-started-background-okr: #9799a9;
$status-discarded-background-okr: rgba(70, 82, 183, 0.2);
$status-postponed-background-okr: rgb(102, 102, 102);
$status-behind-background-okr: #ebb685;
$status-achieved-background-okr: #82d1b5;
$status-not-achieved-background-okr: #d19494;

$status-track-okr: #3b8d70;
$status-risk-okr: #9d5858;
$status-not-started-okr: #5b5d6b;
$status-discarded-okr: rgba(70, 82, 183, 0.2);
$status-postponed-okr: rgb(102, 102, 102);
$status-behind-okr: #ad7f56;

$status-track-border-okr: #82d1b5;
$status-behind-border-okr: #ebb685;
$status-risk-border-okr: #d19494;
$status-not-started-border-okr: #9799a9;
$status-achieved-border-okr: #82d1b5;
$status-not-achieved-border-okr: #d19494;

// end OKR specific

$status-track-background: rgba(130, 209, 181, 0.08);
$status-risk-background: rgba(191, 113, 113, 0.08);
$status-not-started-background: rgba(151, 153, 169, 0.08);
$status-discarded-background: rgba(70, 82, 183, 0.1);
$status-postponed-background: rgba(102, 102, 102, 0.1);
$status-behind-background: rgba(205, 165, 65, 0.13);
$status-achieved-background: rgba(130, 209, 181, 0.08);
$status-not-achieved-background: rgba(191, 113, 113, 0.08);

$status-track-border: #82d1b5;
$status-behind-border: #cda541;
$status-risk-border: #bf7171;
$status-not-started-border: #9799a9;
$status-achieved-border: #82d1b5;
$status-not-achieved-border: #bf7171;
