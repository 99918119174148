@mixin progress-container {
  height: 20px !important;
  border-radius: 3px;
  background-color: $status-background;
  width: calc(100% - 5px);
  margin-left: 2.8px;
}

@mixin progress-bar {
  border-radius: 0px;
  height: 20px !important;
  @media only screen and (max-width: 1750px) {
    height: 20px !important;
  }
}

.ant-progress.system-progress {
  .ant-progress-inner {
    @include progress-container();

    .ant-progress-bg {
      @include progress-bar();
      background-color: $status-default !important;
    }
  }
}

.ant-progress.system-progress-on_track {
  .ant-progress-outer {
    border: 2px solid transparent;
    border-radius: 5px;
  }

  .ant-progress-inner {
    @include progress-container();

    .ant-progress-bg {
      @include progress-bar();
      background-color: $status-track !important;
    }
  }
}

.ant-progress.system-progress-at_risk {
  .ant-progress-outer {
    border: 2px solid transparent;
    border-radius: 5px;
  }

  .ant-progress-inner {
    @include progress-container();

    .ant-progress-bg {
      @include progress-bar();
      background-color: $status-risk !important;
    }
  }
}

.ant-progress.system-progress-not_started {
  .ant-progress-outer {
    border: 2px solid transparent;
    border-radius: 5px;
  }

  .ant-progress-inner {
    @include progress-container();

    .ant-progress-bg {
      @include progress-bar();
      background-color: $status-not-started !important;
    }
  }
}

.ant-progress.system-progress-discarded {
  .ant-progress-outer {
    border: 2px solid transparent;
    border-radius: 5px;
  }

  .ant-progress-inner {
    @include progress-container();

    .ant-progress-bg {
      @include progress-bar();
      background-color: $status-discarded !important;
    }
  }
}

.ant-progress.system-progress-postponed {
  .ant-progress-outer {
    border: 2px solid transparent;
    border-radius: 5px;
  }

  .ant-progress-inner {
    @include progress-container();

    .ant-progress-bg {
      @include progress-bar();
      background-color: $status-postponed !important;
    }
  }
}

.ant-progress.system-progress-behind {
  .ant-progress-outer {
    border: 2px solid transparent;
    border-radius: 5px;
  }

  .ant-progress-inner {
    @include progress-container();

    .ant-progress-bg {
      @include progress-bar();
      background-color: $status-behind-border !important;
    }
  }
}

.ant-progress.system-progress-achieved {
  .ant-progress-outer {
    border: 2px solid transparent;
    border-radius: 5px;
  }

  .ant-progress-inner {
    @include progress-container();

    .ant-progress-bg {
      @include progress-bar();
      background-color: $status-achieved !important;
    }
  }
}

.ant-progress.system-progress-not_achieved {
  .ant-progress-outer {
    border: 2px solid transparent;
    border-radius: 5px;
  }

  .ant-progress-inner {
    @include progress-container();

    .ant-progress-bg {
      @include progress-bar();
      background-color: $status-not-achieved !important;
    }
  }
}

.ant-progress.system-progress-zero-on_track {
  .ant-progress-outer {
    height: 28px;
    border: 2px solid $status-track;
    border-radius: 5px;
  }
  .ant-progress-inner {
    vertical-align: text-top;

    @include progress-container();
    .ant-progress-bg {
      @include progress-bar();
      background-color: $status-track !important;
    }
  }
}

.ant-progress.system-progress-zero-at_risk {
  .ant-progress-outer {
    height: 28px;

    border: 2px solid $status-risk;
    border-radius: 5px;
  }
  .ant-progress-inner {
    vertical-align: text-top;

    @include progress-container();

    .ant-progress-bg {
      @include progress-bar();
      background-color: $status-risk !important;
    }
  }
}

.ant-progress.system-progress-zero-behind {
  .ant-progress-outer {
    height: 28px;

    border: 2px solid $status-behind-border;
    border-radius: 5px;
  }
  .ant-progress-inner {
    vertical-align: text-top;

    @include progress-container();

    .ant-progress-bg {
      @include progress-bar();
      background-color: $status-behind-border !important;
    }
  }
}

.ant-progress.system-progress-zero-postponed {
  .ant-progress-outer {
    height: 28px;

    border: 2px solid $status-postponed;
    border-radius: 5px;
  }
  .ant-progress-inner {
    vertical-align: text-top;

    @include progress-container();

    .ant-progress-bg {
      @include progress-bar();
      background-color: $status-postponed !important;
    }
  }
}

.ant-progress.system-progress-zero-discarded {
  .ant-progress-outer {
    height: 28px;

    border: 2px solid $status-discarded;
    border-radius: 5px;
  }
  .ant-progress-inner {
    vertical-align: text-top;

    @include progress-container();

    .ant-progress-bg {
      @include progress-bar();
      background-color: $status-discarded !important;
    }
  }
}

.ant-progress.system-progress-zero-not_started {
  .ant-progress-outer {
    height: 28px;

    border: 2px solid $status-not-started;
    border-radius: 5px;
  }
  .ant-progress-inner {
    vertical-align: text-top;

    @include progress-container();

    .ant-progress-bg {
      @include progress-bar();
      background-color: $status-not-started !important;
    }
  }
}
