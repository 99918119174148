@mixin beta-badge {
  color: #256b39;
  background: #5cb47514;
  border: 1px solid #5cb475;
  border-radius: 3px;
  height: 20px;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  text-transform: uppercase;
}

@mixin dimension-generator($start, $attribute, $limit) {
  @for $i from $start through $limit {
    &#{"-#{$i}"} {
      #{$attribute}: #{$i}px;
    }
  }
}
