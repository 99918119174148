.opacity {
  &-1 {
    opacity: 1;
  }

  &-05 {
    opacity: 0.5;
  }

  &-0 {
    opacity: 0;
  }
}
