.display {
  &-none {
    display: none;
  }
  &-block {
    display: block;
  }
  &-inlineblock {
    display: inline-block;
  }
  &-inline {
    display: inline;
  }
}
