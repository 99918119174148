.ant-tag.system-tag {
  font-weight: 500;
  font-size: 12px;
  font-family: $font-primary;
  border-radius: 3px;

  padding: 1px 7px;

  margin: 0;

  width: fit-content;
  &-purple {
    background-color: $tag-background-purple;
    color: $tag-text-purple;
    border: 1px solid $tag-border-purple;
  }

  &-status {
    &-on_track {
      background-color: $status-track-background;
      border: 1px solid $status-track-border;
      color: $status-track;

      &-okr {
        background-color: $status-track-background-okr;
        color: $status-track-okr;
        border: 1px solid $status-track-border-okr;
      }
    }

    &-at_risk {
      background-color: $status-risk-background;
      border: 1px solid;
      color: $status-risk;

      &-okr {
        background-color: $status-risk-background-okr;
        color: $status-risk-okr;
        border: 1px solid $status-risk-border-okr;
      }
    }

    &-not_started {
      background-color: $status-not-started-background;
      border: 1px solid $status-not-started-border;
      color: $status-not-started;

      &-okr {
        background-color: $status-not-started-background-okr;
        color: $status-not-started-okr;
        border: 1px solid $status-not-started-border-okr;
      }
    }

    &-discarded {
      background-color: $status-discarded-background;
      border: 1px solid $status-discarded;
      color: $status-discarded;

      &-okr {
        background-color: $status-discarded-background-okr;
        color: $status-discarded-okr;
        border: 1px solid $status-discarded-okr;
      }
    }

    &-postponed {
      background-color: $status-postponed-background;
      border: 1px solid $status-postponed;
      color: $status-postponed;

      &-okr {
        background-color: $status-postponed-background-okr;
        color: $status-postponed-okr;
        border: 1px solid $status-postponed-okr;
      }
    }

    &-behind {
      background-color: $status-behind-background;
      border: 1px solid $status-behind-border;
      color: $status-behind;

      &-okr {
        background-color: $status-behind-background-okr;
        color: $status-behind-okr;
        border: 1px solid $status-behind-border-okr;
      }
    }
    &-achieved {
      background-color: $status-track-background;
      border: 1px solid $status-track-border;
      color: $status-achieved;
    }
    &-not_achieved {
      background-color: $status-risk-background;
      border: 1px solid;
      color: $status-not-achieved;
    }
  }
}
