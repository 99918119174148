/** Avenir Next **/

@font-face {
  font-family: "Avenir Next";
  src: url("avenir-next-regular.woff") format("woff");
  font-weight: 200;
}

@font-face {
  font-family: "Avenir Next";
  src: url("avenir-next-medium.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "Avenir Next";
  src: url("avenir-next-demi.woff") format("woff");
  font-weight: bold;
}

/* @font-face {
  font-family: 'Avenir Next';
  src: url('avenir-next-bold.woff') format('woff');
  font-weight: 600;
} */
