@import "../../mixins.scss";

.w {
  @include dimension-generator(1, "width", 40);
  &-v {
    width: 100vw;
  }

  &-100 {
    width: 100%;
  }
  &-50 {
    width: 50%;
  }

  &-inherit {
    width: inherit;
  }

  &-fit {
    width: fit-content;
  }

  &-max {
    width: max-content;
  }
}

.h {
  @include dimension-generator(1, "height", 40);
  &-v {
    height: 100vh;
  }

  &-100 {
    height: 100%;
  }

  &-50 {
    height: 50%;
  }

  &-0 {
    height: 0;
  }

  &-inherit {
    height: inherit;
  }

  &-fit {
    height: fit-content;
  }

  &-auto {
    height: auto;
  }
}
