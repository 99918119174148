.system-card {
  width: 100%;

  border: 1px solid $border-muted;
  box-shadow: 0px 2px 3px $card-box-shadow;
  border-radius: 5px;

  background-color: $card-background;

  // for overrriding default antd styling
  &-important {
    border: 1px solid $border-muted !important;
    box-shadow: 0px 2px 3px $card-box-shadow !important;
    border-radius: 5px !important;

    background-color: $card-background !important;
  }

  &-selected {
    background-color: $background-primary-light;
    border: 1px solid $border-highlight;
  }

  &-borderless {
    border: 0px;
  }
}
