@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700&display=swap");

$grey-border: #ebebeb;
$white-background: #ffff;
$color-active-green: #00ae7b;
$happy-color: #b2d4f0;
$neutral-color: #d2d2d2;
$unhappy-color: #f18287;

$happiness-score: #9177fd;
$manager-score: #c88bf6;

$blue-color: #4e93cb;
$btn-red-color: #c73f2a;
$btn-blue-color: #62ace9;
$border-grey-color: #d7d7e9;
$border-dark-grey-color: rgba(72, 72, 72, 0.2);
$border-radius-sm: 3px;
$border-radius-normal: 5px;

$body-sidebar-width: 17.78%;
$body-content-width: 100% - 17.78%;
$font-size-normal: 16px;
$font-size-headers: 22px;
$font-family: "Avenir Next", Georgia, sans-serif;

$primary-color: #4652b7;
$secondary-color: #00ae7c;
$primary-color-dark-1: #38429b;
$primary-color-dark-2: #232d83;
// $secondary-color: #4AA87D;
$body-background-color: #ffffff;
$background-primary-color: #f7f7fb;
$background-secondary-color: #d7d7e9;
$background-tertiary-color: #e6ecf5;

$black-primary-color: #13141e;
$black-secondary-color: #12131e;
$black-tertiary-color: #6a6b79;
$black-text-faded-color: #9799a9;
$black-text-color: #333;

$header-height: 92px;
$content-right-padding: 40px;
$content-left-padding: 29px;

$pending-status-color: #fef3d8;
$not-done-status-color: #ffdfe0;
$done-status-color: #ccf4ee;

$success-color: #9ec983;
$warning-color: #e6a37e;
$danger-color: #e87d5b;
$navy-gray-color: #a8d8f3;

$primary-nav-width: 70px;

:export {
  primaryColor: $primary-color;
  headerHeight: $header-height;
  contentRightPadding: $content-right-padding;
  contentLeftPadding: $content-left-padding;
  secondaryColor: $secondary-color;
  blackPrimaryColor: $black-primary-color;
  blackSecondaryColor: $black-secondary-color;
  blackTertiaryColor: $black-tertiary-color;
  backgroundPrimaryColor: $background-primary-color;
  backgroundSecondaryColor: $background-secondary-color;
  backgroundTertiaryColor: $background-tertiary-color;
  colorActiveGreen: $color-active-green;
  neutralColor: $neutral-color;
  pendingStatusColor: $pending-status-color;
  unhappyColor: $unhappy-color;
  primaryColorDark1: $primary-color-dark-1;
  borderGreyColor: $border-grey-color;
  blackTextFadedColor: $black-text-faded-color;
  blackTextColor: $black-text-color;
  successColor: $success-color;
  warningColor: $warning-color;
  dangerColor: $danger-color;
  navyGrayColor: $navy-gray-color;
}
