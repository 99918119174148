.flex {
  display: flex;

  &-inline {
    display: inline-flex;
  }

  &-col {
    flex-direction: column;
  }

  &-rrow {
    flex-direction: row-reverse;
  }

  &-rcol {
    flex-direction: column-reverse;
  }

  &-wrap {
    flex-wrap: wrap;
  }
}

@for $i from 1 through 10 {
  .flex-grow-#{$i} {
    flex-grow: $i;
  }
}

.align {
  &-start {
    align-items: flex-start;
  }
  &-center {
    align-items: center;
  }
  &-end {
    align-items: flex-end;
  }

  &-baseline {
    align-items: baseline;
  }
}

.justify {
  &-center {
    justify-content: center;
  }

  &-end {
    justify-content: flex-end;
  }

  &-between {
    justify-content: space-between;
  }

  &-around {
    justify-content: space-around;
  }

  &-start {
    justify-content: flex-start;
  }
}

.overflow {
  &-hidden {
    overflow: hidden;
  }

  &-scroll {
    overflow: scroll;
  }

  &-y {
    &-scroll {
      overflow-y: scroll;
    }
    &-auto {
      overflow-y: auto;
    }
    &-hidden {
      overflow-y: hidden;
    }
  }
  &-x {
    &-scroll {
      overflow-x: scroll;
    }
    &-auto {
      overflow-x: auto;
    }
    &-hidden {
      overflow-x: hidden;
    }
  }
}
