.bg {
  &-title {
    &-light {
      background-color: $background-title-light;
    }
  }

  &-light {
    background-color: $background-primary-light;
  }

  &-light-accent {
    background-color: $background-card-title;
  }

  &-collapse-light {
    background-color: $background-card-light;
  }

  &-white {
    background-color: #fff;
  }

  &-transparent {
    background-color: transparent;

    &-hover {
      &:hover {
        background-color: transparent;
      }
    }
  }

  &-accent {
    background-color: $primary-color;

    &-important {
      background-color: $primary-color !important;
    }

    &-light {
      background-color: $primary-color-light;
    }

    &-darker {
      background-color: $darker-primary-color;
    }

    &-sidebar {
      background-color: $background-accent-sidebar;
    }
  }

  // for container around actual card
  &-card {
    &-accent {
      &-light {
        background-color: $background-accent-container;
        &-important {
          background-color: $background-accent-container !important;
        }
      }
    }
    &-light {
      background-color: $card-background;
      &-ash {
        background-color: $card-light-ash;
      }
    }
    &-container {
      background-color: $background-card-container;
    }
    &-purple {
      background-color: $background-dark-purple;
    }
    &-performance-review {
      background-color: $background-performance-review-color;
    }
  }

  &-survey {
    &-card {
      &-live {
        background-color: $survey-card-background-live;
      }
      &-draft {
        background-color: $survey-card-background-draft;
      }
      &-scheduled {
        background-color: $survey-card-background-scheduled;
      }
      &-completed {
        background-color: $survey-card-background-completed;
      }
    }

    &-status {
      &-live {
        background-color: $survey-status-background-live;
      }
      &-draft {
        background-color: $survey-status-background-draft;
      }
      &-scheduled {
        background-color: $survey-status-background-scheduled;
      }
      &-completed {
        background-color: $survey-status-background-completed;
      }
    }
  }

  &-status {
    &-on_track {
      background-color: $status-track;
    }

    &-at_risk {
      background-color: $status-risk;
    }

    &-not_started {
      background-color: $status-not-started;
    }

    &-discarded {
      background-color: $status-discarded;
    }

    &-postponed {
      background-color: $status-postponed;
    }

    &-behind {
      background-color: $status-behind-border;
    }
    &-achieved {
      background-color: $status-achieved;
    }
    &-not_achieved {
      background-color: $status-not-achieved;
    }
  }
}
