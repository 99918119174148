@import url("https://fonts.googleapis.com/css?family=PT+Sans");
@import "./resources/fonts/avenirNext/font.css";
@import "./styles/variables.scss";
@import "./styles/partials/main.scss";

body {
  background-color: #fff !important;
  font-family: "Avenir Next", Georgia, sans-serif !important;
  color: #000000;
  height: auto;
  width: 100%;
}

.woot-widget-bubble.woot-elements--right {
  z-index: 999 !important;
}
